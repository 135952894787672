@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.navbar {
    padding: 16px 0;
    transition: background-color 0.2s ease, padding 0.2s ease;
    background-color: $navbar-background-color;
    box-shadow: $box-shadow-small;
    border-bottom-left-radius: $border-radius-extra-large;
    border-bottom-right-radius: $border-radius-extra-large;

    &.scrolled {
        padding: 8px 0;
    }

    @include max-media-sm {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 16px;
        width: 40%;
        box-shadow: none;
        background-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &.scrolled {
            padding: 16px;
        }
    }
}

.sidebar {
    @include max-media-sm {
        height: 100%;
        padding: 16px 0;
        box-shadow: $box-shadow-small;
        background-color: $navbar-background-color;
    }
}

.dynamic-sidebar-width {
    @include media-md {
        width: 24%;
    }

    @include media-lg {
        width: 20%;
    }

    @include media-xl {
        width: 18%;
    }

    @include media-xxl {
        width: 16%;
    }
}

.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}

.navbar-toggler {
    border: none;
    padding: 0;

    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
    }
}

.navbar-toggler-box,
.navbar-toggler-box-closed {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $navbar-background-color;
    padding: 4px;
    box-shadow: $box-shadow-small;
    border-radius: 4px;
}

.navbar-toggler-box-closed {
    box-shadow: none;
    padding: 4px 0;
}

.navbar-toggler-icon {
    background: $secondary-background-color;
    width: 28px;
    height: 28px;
}

.nav-link {
    color: $nav-link-color;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    [dir='rtl'] & {
        font-size: 15px;
    }
}

.nav-link:hover {
    color: $nav-link-hover-color;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: $nav-link-active-color;
}

.navbar-brand {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.4rem;
    color: $navbar-brand-color;
}

.navbar-brand:hover {
    color: $nav-link-hover-color;
}

.dropdown-menu {
    min-width: 100%;
}

.dropdown-item:hover {
    background-color: $nav-link-hover-color;
    color: $navbar-background-color;
}

.dropdown-item:focus {
    background-color: $nav-link-hover-color;
}

.language-icon {
    width: 28px;
    height: 28px;
}

.navbar-img {
    border-radius: 50%;
    box-shadow: $box-shadow-medium;
    border: 1px solid rgba($color: $secondary-color, $alpha: 0.2);

    @include media-md {
        height: 160px;
        width: 160px;
    }

    @include media-lg {
        height: 160px;
        width: 160px;
    }

    @include media-xl {
        height: 180px;
        width: 180px;
    }

    @include media-xxl {
        height: 200px;
        width: 200px;
    }
}
