@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

#resume {
    position: relative;
    z-index: 1;

    @include min-media-lg {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(rgba(33, 37, 41, 0.8), rgba(33, 37, 41, 0.8)),
                url('../../../assets/images/home/resume-bg.jpg');
            background-position: center;
            background-repeat: no-repeat;
            background-attachment: fixed;
            z-index: -1;
        }
    }

    .resume-subtitle {
        color: $primary-color;
        text-align: center;
    }

    .divider {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        width: 90%;
        margin: 24px auto;
    }
}
