@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.list-item {
    display: flex;
    flex-direction: column;
    justify-content: start;

    :dir(ltr) & {
        align-items: start;
    }

    :dir(rtl) & {
        align-items: end;
    }
}

.list-item-row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    :dir(ltr) & {
        flex-direction: row;
    }

    :dir(rtl) & {
        flex-direction: row-reverse;
    }
}

.list-item-logo {
    :dir(ltr) & {
        margin-right: 16px;
    }

    :dir(rtl) & {
        margin-left: 16px;
    }

    @include max-media-md {
        width: 68px;
        height: 68px;
    }

    @include media-lg {
        width: 70px;
        height: 70px;
    }

    @include media-xl {
        width: 78px;
        height: 78px;
    }

    @include media-xxl {
        width: 88px;
        height: 88px;
    }
}

.list-item-details-container {
    display: flex;
    flex-direction: column;
    justify-content: start;

    :dir(ltr) & {
        align-items: start;
    }

    :dir(rtl) & {
        align-items: end;
    }
}

.list-item-title {
    color: $tertiary-color;
    font-size: 1.1rem;
    margin: 0;
    text-align: start;
    font-weight: bold;

    @include max-media-md {
        font-size: 1.05rem;
    }
}

.list-item-subtitle {
    color: $muted-text-color;
    font-size: 1.1rem;
    margin-bottom: 4px;
    text-align: start;

    @include max-media-md {
        font-size: 1.05rem;
    }
}

.list-item-point {
    font-size: 0.9rem;
}
