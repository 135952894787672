@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins.scss';

#skills {
    background-color: $secondary-background-color;
    border-radius: $border-radius-extra-large;

    .skills-row {
        text-align: center;

        :dir(ltr) & {
            flex-direction: row;
        }

        :dir(rtl) & {
            flex-direction: row-reverse;
        }
    }

    .skill-item {
        margin-bottom: 32px;
    }

    .skill-icon {
        width: 88px;
        height: 88px;

        @include max-media-sm {
            width: 64px;
            height: 64px;
        }

        @include media-md {
            width: 70px;
            height: 70px;
        }
    }

    .skill-title {
        font-weight: 500;
        color: $primary-color;
        margin-top: 8px;
    }
}
