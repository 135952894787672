@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins.scss';

.box-item {
    position: relative;
    display: block;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        .box-item-caption {
            height: 100%;
        }
    }
}

.box-item-caption {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 25%;
    position: absolute;
    bottom: 0;
    text-align: center;
    opacity: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: all 0.3s ease;
}

.box-item-subtitle {
    font-size: 0.85rem;
    font-weight: 600;
    color: rgba(256, 256, 256, 0.6);
}

.box-item-title {
    font-size: 1.2rem;
    font-weight: 600;
}

.box-item .box-item-caption {
    opacity: 1;
}

.box-item-img {
    width: 100%;
    border-radius: 8px;
    object-fit: cover !important;

    @include media-xs {
        height: 260px;
    }

    @include media-sm {
        height: 220px;
    }

    @include media-md {
        height: 260px;
    }

    @include media-lg {
        height: 240px;
    }

    @include media-xl {
        height: 280px;
    }

    @include media-xxl {
        height: 300px;
    }
}

.image-gallery-icon {
    color: #ffffff;

    &:hover {
        color: #e4e4e4;
    }
}

.image-gallery-thumbnail {
    &:hover {
        border: 1px solid transparent !important;
    }
}

.image-gallery-thumbnail.active {
    border: 2px solid $primary-color !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    @include media-xs {
        max-height: 300px;
    }

    @include media-sm {
        max-height: 320px;
    }

    @include media-md {
        max-height: 350px;
    }

    @include media-lg {
        max-height: 500px;
    }

    @include media-xl {
        max-height: 500px;
    }

    @include media-xxl {
        max-height: 500px;
    }
}

.fullscreen,
.fullscreen * {
    max-height: calc(100vh - 100px) !important;
}

.close-modal-icon-wrapper {
    width: 38px;
    height: 38px;
    cursor: pointer;
}

.close-modal-icon {
    width: 100%;
    height: 100%;
}

.store-badge {
    width: 120px;
    height: 40px;
}
