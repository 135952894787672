@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import './variables.scss';
@import './mixins.scss';

// Shared Styles

body {
    font-family: $font-family-base;
    background-color: $primary-background-color;
    background-image: radial-gradient(circle at center, #000 15%, transparent 15%);
    background-size: 10px 10px;
}

.bg-primary {
    background-color: $primary-background-color !important;
}

.bg-secondary {
    background-color: $secondary-background-color !important;
}

.text-primary {
    color: $primary-color !important;
}

.text-secondary {
    color: $secondary-color !important;
}

.text-tertiary {
    color: $tertiary-color !important;
}

.text-muted {
    color: $muted-text-color !important;
}

.btn {
    padding: 12px 24px;
    border-radius: 4px;
    transition: background-color 0.3s;

    @include max-media-md {
        padding: 8px 16px;
        font-size: 0.95rem;
    }
}

.btn-primary {
    color: $btn-text-color;
    background-color: $primary-color !important;
    border-color: $primary-color !important;

    &:hover {
        color: $btn-text-color;
        background-color: $primary-color-hover !important;
        border-color: $primary-color-hover !important;
    }

    &:active {
        color: $btn-text-color;
        background-color: $primary-color-active !important;
        border-color: $primary-color-active !important;
    }
}

.btn-outline-primary {
    color: $primary-color;
    background-color: transparent !important;
    border-color: $primary-color !important;

    &:hover {
        color: $btn-text-color;
        background-color: $primary-color-hover !important;
        border-color: $primary-color-hover !important;
    }

    &:active {
        color: $btn-text-color;
        background-color: $primary-color-active !important;
        border-color: $primary-color-active !important;
    }
}

.btn-secondary {
    color: $btn-text-color;
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;

    &:hover {
        color: $btn-text-color;
        background-color: $secondary-color-hover !important;
        border-color: $secondary-color-hover !important;
    }

    &:active {
        color: $btn-text-color;
        background-color: $secondary-color-active !important;
        border-color: $secondary-color-active !important;
    }
}

.btn-outline-secondary {
    color: $secondary-color;
    background-color: transparent !important;
    border-color: $secondary-color !important;

    &:hover {
        color: $btn-text-color;
        background-color: $secondary-color-hover !important;
        border-color: $secondary-color-hover !important;
    }

    &:active {
        color: $btn-text-color;
        background-color: $secondary-color-active !important;
        border-color: $secondary-color-active !important;
    }
}

.btn-tertiary {
    color: $secondary-color;
    background-color: $tertiary-color !important;
    border-color: $tertiary-color !important;

    &:hover {
        color: $secondary-color;
        background-color: $tertiary-color-hover !important;
        border-color: $tertiary-color-hover !important;
    }

    &:active {
        color: $secondary-color;
        background-color: $tertiary-color-active !important;
        border-color: $tertiary-color-active !important;
    }
}

.btn-outline-tertiary {
    color: $tertiary-color !important;
    background-color: transparent !important;
    border-color: $tertiary-color !important;

    &:hover {
        color: $secondary-color !important;
        background-color: $tertiary-color-hover !important;
        border-color: $tertiary-color-hover !important;
    }

    &:active {
        color: $secondary-color !important;
        background-color: $tertiary-color-active !important;
        border-color: $tertiary-color-active !important;
    }
}

.btn-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
    font-size: 1.2rem;

    @include media-xs {
        width: 40px;
        height: 40px;
    }

    @include media-sm {
        width: 46px;
        height: 46px;
    }

    @include media-md {
        width: 40px;
        height: 40px;
    }

    @include media-lg {
        width: 48px;
        height: 48px;
    }

    @include media-xl {
        width: 50px;
        height: 50px;
    }

    @include media-xxl {
        width: 50px;
        height: 50px;
    }
}

.btn-rounded {
    border-radius: 8px;
}

.pointer {
    cursor: pointer;
}

.x-space {
    width: 16px;
}

.y-space {
    height: 16px;
}

.spinner {
    width: 90px;
    margin: 48px;
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn 1s forwards;
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-up {
    opacity: 0;
    transform: translateY(50px);
    animation: fadeUp 1s forwards;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.pulse {
    animation: pulse 1.5s forwards;
}

@keyframes slideIn {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.slide-in {
    animation: slideIn 1s forwards;
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }
    25%,
    75% {
        transform: translateX(-20px);
    }
    50% {
        transform: translateX(20px);
    }
}

.shake {
    animation: shake 0.6s forwards;
}

@keyframes swing {
    20% {
        transform: rotate3d(0, 0, 1, 15deg);
    }
    40%,
    60% {
        transform: rotate3d(0, 0, 1, -10deg);
    }
    80% {
        transform: rotate3d(0, 0, 1, 5deg);
    }
    100% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

.swing {
    transform-origin: top center;
    animation: swing 0.8s forwards;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rotate {
    animation: rotate 1s linear forwards;
}

@keyframes flip {
    0% {
        transform: perspective(400px) rotateY(0);
    }
    100% {
        transform: perspective(400px) rotateY(1turn);
    }
}

.flip {
    animation: flip 2s forwards;
}

@keyframes wobble {
    0% {
        transform: translateX(0%);
    }
    15% {
        transform: translateX(-25%) rotate(-5deg);
    }
    30% {
        transform: translateX(20%) rotate(3deg);
    }
    45% {
        transform: translateX(-15%) rotate(-3deg);
    }
    60% {
        transform: translateX(10%) rotate(2deg);
    }
    75% {
        transform: translateX(-5%) rotate(-1deg);
    }
    100% {
        transform: translateX(0%);
    }
}

.wobble {
    animation: wobble 1s forwards;
}

@keyframes jello {
    0%,
    100% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(0.9, 1.1);
    }
    50% {
        transform: scale(1.1, 0.9);
    }
    75% {
        transform: scale(0.95, 1.05);
    }
}

.jello {
    animation: jello 1s forwards;
}
