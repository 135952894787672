@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins.scss';

#about {
    background-color: $secondary-background-color;
    border-radius: $border-radius-extra-large;

    .about-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -10px;
    }

    .about-content {
        text-align: start;
        letter-spacing: 1px;
        line-height: 1.8;

        @include max-media-md {
            text-align: center;
            letter-spacing: normal;
        }

        @include max-media-md {
            font-size: 1rem;
        }

        @include media-lg {
            font-size: 1.1rem;
        }

        @include media-xl {
            font-size: 1.2rem;
        }

        @include media-xxl {
            font-size: 1.2rem;
        }
    }

    .about-experience {
        @include max-media-md {
            margin-top: 36px;
        }

        margin-top: 56px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .about-experience-highlight {
        font-size: clamp(3.2rem, 5.8vw, 4.8rem);
        font-weight: bold;
        color: $primary-color;
        margin: 0 8px;
    }

    .about-experience-text {
        font-size: clamp(1.2rem, 1.4vw, 1.6rem);
        font-weight: 600;
        color: $secondary-color;
        line-height: 26px;

        @include max-media-sm {
            line-height: 20px;
        }
    }
}
