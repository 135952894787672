@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins.scss';

.form-control {
    resize: none;
    border-radius: 12px;
    padding: 16px;
    background-color: $input-background-color;
    color: $input-text-color;
    letter-spacing: 1px;
    font-weight: 600;

    &::placeholder {
        color: $muted-text-color;
    }

    &:focus {
        border-color: lighten($primary-color, 20%);
        box-shadow: 0 0 0 0.05rem lighten($primary-color, 20%);
        outline: none;
    }
}

.input-wrapper {
    @include media-xs {
        height: 58px;
        margin-bottom: 18px;
    }

    @include media-sm {
        height: 60px;
        margin-bottom: 18px;
    }

    @include media-md {
        height: 62px;
        margin-bottom: 18px;
    }

    @include media-lg {
        height: 64px;
        margin-bottom: 18px;
    }

    @include media-xl {
        height: 66px;
        margin-bottom: 20px;
    }

    @include media-xxl {
        height: 68px;
        margin-bottom: 20px;
    }
}

input {
    @include media-xs {
        height: 56px;
    }

    @include media-sm {
        height: 58px;
    }

    @include media-md {
        height: 60px;
    }

    @include media-lg {
        height: 62px;
    }

    @include media-xl {
        height: 64px;
    }

    @include media-xxl {
        height: 66px;
    }
}

.textarea-wrapper {
    height: 178px;
}

textarea {
    height: 156px;
}

.invalid-input {
    color: $warning-color;
    font-size: 0.75em;
    padding: 0 8px;
}
