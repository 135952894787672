@import '../../../assets/styles/mixins.scss';

#contact {
    #contactForm {
        @include media-xs {
            padding: 0 2%;
        }

        @include media-sm {
            padding: 0 5%;
        }

        @include media-md {
            padding: 0 10%;
        }

        @include media-lg {
            padding: 0 15%;
        }

        @include media-xl {
            padding: 0 20%;
        }

        @include media-xxl {
            padding: 0 25%;
        }
    }
}
