// Colors
$primary-color: #ffa500;
$primary-color-hover: darken($primary-color, 5%);
$primary-color-active: darken($primary-color, 10%);

$secondary-color: #212529;
$secondary-color-hover: lighten($secondary-color, 5%);
$secondary-color-active: lighten($secondary-color, 10%);

$tertiary-color: #f8f8f8;
$tertiary-color-hover: darken($tertiary-color, 5%);
$tertiary-color-active: darken($tertiary-color, 10%);

$primary-background-color: #212529;
$secondary-background-color: #f8f8f8;

$primary-background-text-color: #f8f8f8;
$secondary-background-text-color: #212529;

$btn-text-color: #ffffff;
$muted-text-color: #a4a4a4;

$navbar-background-color: #f8f8f8;
$navbar-brand-color: #212529;
$nav-link-color: #212529;
$nav-link-hover-color: #212529;
$nav-link-active-color: #ffa500;

$header-background-color: #212529;
$header-text-color: #f8f8f8;

$footer-background-color: #f8f8f8;
$footer-text-color: #212529;

$input-background-color: #ffffff;
$input-text-color: #212529;

$success-color: #28a745;
$warning-color: #ffc107;
$error-color: #dc3545;

// Typography
$font-family-base: 'Open Sans';
$font-family-heading: 'Open Sans';
$font-size-base: 16px;
$font-size-large: 24px;
$font-size-small: 12px;

// Spacing
$spacing-unit: 8px;
$spacing-small: $spacing-unit * 0.5; // 4px
$spacing-medium: $spacing-unit; // 8px
$spacing-large: $spacing-unit * 2; // 16px
$spacing-extra-large: $spacing-unit * 3; // 24px

// Breakpoints
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-extra-large: 1200px;

// Border Radius
$border-radius-small: 4px;
$border-radius-medium: 8px;
$border-radius-large: 16px;
$border-radius-extra-large: 32px;

// Box Shadows
$box-shadow-small: 0 2px 4px rgba(0, 0, 0, 0.2);
$box-shadow-medium: 0 4px 8px rgba(0, 0, 0, 0.2);
$box-shadow-large: 0 8px 16px rgba(0, 0, 0, 0.2);
$box-shadow-extra-large: 0 16px 32px rgba(0, 0, 0, 0.2);

$box-shadow-small-primary: 0 2px 4px rgba($primary-color, 0.4);
$box-shadow-medium-primary: 0 4px 8px rgba($primary-color, 0.4);
$box-shadow-large-primary: 0 8px 16px rgba($primary-color, 0.4);
$box-shadow-extra-large-primary: 0 16px 32px rgba($primary-color, 0.4);
