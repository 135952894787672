@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;

    @include media-xs {
        // padding-top: 44px;
    }

    @include media-sm {
        // padding-top: 50px;
    }

    @include media-md {
        padding-top: 56px;
    }

    @include media-lg {
        padding-top: 72px;
    }

    @include media-xl {
        padding-top: 76px;
    }

    @include media-xxl {
        padding-top: 76px;
    }

    .header-container {
        position: relative;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        text-align: start;

        @include media-xs {
            margin-top: 44px;
            align-items: center;
            text-align: center;
        }

        @include media-sm {
            margin-top: 50px;
            align-items: center;
            text-align: center;
        }
    }

    .header-welcome {
        font-weight: 400;
        color: $header-text-color;
        margin-bottom: 8px;
        margin-top: 0;

        @include max-media-sm {
            margin-top: 24px;
        }
    }

    .header-title {
        font-weight: bold;
        color: $primary-color;

        @include media-xs {
            font-size: 46px;
        }

        @include media-sm {
            font-size: 50px;
        }

        @include media-md {
            font-size: 44px;
        }

        @include media-lg {
            font-size: 60px;
        }

        @include media-xl {
            font-size: 72px;
        }

        @include media-xxl {
            font-size: 84px;
        }
    }

    .header-subtitle {
        font-weight: 700;
        color: $muted-text-color;
        margin-bottom: 26px;

        @include media-xs {
            margin-bottom: 26px;
        }

        @include media-md {
            margin-bottom: 16px;
        }
    }

    .header-welcome,
    .header-subtitle {
        @include media-xs {
            font-size: 36px;
        }

        @include media-sm {
            font-size: 40px;
        }

        @include media-md {
            font-size: 36px;
        }

        @include media-lg {
            font-size: 48px;
        }

        @include media-xl {
            font-size: 54px;
        }

        @include media-xxl {
            font-size: 54px;
        }
    }

    .header-icon {
        font-size: 24px;
    }

    .header-summary {
        color: $header-text-color;
        font-weight: 500;
        padding: 8px;
        max-width: 500px;

        @include max-media-sm {
            font-weight: 600;
        }
    }

    .header-sticker {
        position: absolute;
        z-index: 1;
        color: $secondary-color;
        transform: translate(-50%, -50%);
        font-weight: bold;
        transform: rotate(28deg);
        display: inline-block;
        -webkit-text-stroke: 1px $secondary-color;

        @include media-xs {
            font-size: 22px;
            top: 82%;
            left: 28%;
        }

        @include media-sm {
            font-size: 24px;
            top: 82%;
            left: 31%;
        }

        @include media-md {
            font-size: 26px;

            :dir(ltr) & {
                top: 74%;
                left: 65%;
            }
            :dir(rtl) & {
                top: 84%;
                right: 65%;
            }
        }

        @include media-lg {
            font-size: 28px;

            :dir(ltr) & {
                top: 74%;
                left: 61%;
            }
            :dir(rtl) & {
                top: 84%;
                right: 61%;
            }
        }

        @include media-xl {
            font-size: 30px;

            :dir(ltr) & {
                top: 74%;
                left: 62%;
            }
            :dir(rtl) & {
                top: 84%;
                right: 62%;
            }
        }

        @include media-xxl {
            font-size: 32px;

            :dir(ltr) & {
                top: 74%;
                left: 62%;
            }
            :dir(rtl) & {
                top: 84%;
                right: 62%;
            }
        }
    }

    .header-profile {
        position: relative;
        margin-bottom: 0;

        &::before {
            content: '';
            position: absolute;
            top: 34%;
            left: 46%;
            width: 600px;
            height: 600px;
            transform: translate(-50%, -50%);
            z-index: -1;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='grad' x1='0%' y1='0%' x2='100%' y2='0%25'%3E%3Cstop offset='0%' style='stop-color:%23FFA500;stop-opacity:1' /%3E%3Cstop offset='100%' style='stop-color:%23FFDF74;stop-opacity:1' /%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill='url(%23grad)' d='M47.9,-52.3C58.6,-45.3,59.9,-24.4,63.2,-4.1C66.5,16.2,71.8,35.9,64.6,45.2C57.4,54.4,37.7,53.3,23.5,55.5C9.2,57.7,0.5,63.2,-13.2,64.6C-26.9,66.1,-44.5,63.5,-50.1,54.6C-55.8,45.8,-49.6,30.6,-46.9,18C-44.2,5.3,-45,-4.8,-43.1,-14.1C-41.1,-23.4,-36.4,-31.9,-29.2,-40.5C-22,-49.2,-11,-57.9,4.2,-61.3C19.5,-64.7,39,-62.9,47.9,-52.3Z' transform='translate(100 100)' /%3E%3C/svg%3E");
            background-size: cover;

            @include media-xs {
                top: 56%;
                left: 42%;
                width: 400px;
                height: 400px;
            }

            @include media-sm {
                width: 450px;
                height: 450px;

                :dir(ltr) & {
                    top: 55%;
                    left: 44%;
                }
                :dir(rtl) & {
                    top: 55%;
                    left: 44%;
                }
            }

            @include media-md {
                width: 450px;
                height: 450px;

                :dir(ltr) & {
                    top: 56%;
                    left: 60%;
                }
                :dir(rtl) & {
                    top: 56%;
                    left: 15%;
                }
            }

            @include media-lg {
                width: 460px;
                height: 460px;

                :dir(ltr) & {
                    top: 56%;
                    left: 46%;
                }
                :dir(rtl) & {
                    top: 56%;
                    left: 35%;
                }
            }

            @include media-xl {
                width: 550px;
                height: 550px;

                :dir(ltr) & {
                    top: 57%;
                    left: 43%;
                }
                :dir(rtl) & {
                    top: 57%;
                    left: 37%;
                }
            }

            @include media-xxl {
                width: 600px;
                height: 600px;

                :dir(ltr) & {
                    top: 56%;
                    left: 43%;
                }
                :dir(rtl) & {
                    top: 56%;
                    left: 39%;
                }
            }
        }

        .profile-img {
            height: 700px;
            object-fit: cover;

            @include media-xs {
                height: 440px;
            }

            @include media-sm {
                height: 520px;
            }

            @include media-md {
                height: 500px;
            }

            @include media-lg {
                height: 520px;
            }

            @include media-xl {
                height: 600px;
            }

            @include media-xxl {
                height: 680px;
            }
        }
    }

    .header-social-wrapper-small {
        display: none;

        @include max-media-md {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
        }
    }

    .header-social-wrapper-large {
        display: block;

        @include max-media-md {
            display: none;
        }

        .social-item-1,
        .social-item-2,
        .social-item-3,
        .social-item-4 {
            position: absolute;
            width: 60px;
        }

        .social-item-1 {
            top: 3%;

            :dir(ltr) & {
                right: 13%;
            }
            :dir(rtl) & {
                right: 81%;
            }
        }

        .social-item-2 {
            top: 16%;

            :dir(ltr) & {
                right: 7%;
            }
            :dir(rtl) & {
                right: 87%;
            }
        }

        .social-item-3 {
            top: 32%;

            :dir(ltr) & {
                right: 3%;
            }
            :dir(rtl) & {
                right: 91%;
            }
        }

        .social-item-4 {
            :dir(ltr) & {
                right: 1%;
            }
            :dir(rtl) & {
                right: 93%;
            }
        }
    }
}
