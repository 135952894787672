@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins.scss';

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    box-shadow: $box-shadow-medium;
    border-radius: 8px;
    padding: 0 16px;
    transition: all 0.25s ease;
    border: none;

    &:hover {
        box-shadow: $box-shadow-medium-primary;
    }
}
.card-icon {
    width: 100px;
    height: 100px;
}

.card-title {
    font-weight: 600;
    color: $secondary-color;
    margin: 16px 0;
}

.card-subtitle {
    color: $secondary-color;
}
