@import '../../assets/styles/mixins.scss';

#home-screen {
    section {
        @include media-xs {
            padding: 62px 0;
        }

        @include media-sm {
            padding: 70px 0;
        }

        @include media-md {
            padding: 80px 0;
        }

        @include media-lg {
            padding: 90px 0;
        }

        @include media-xl {
            padding: 90px 0;
        }

        @include media-xxl {
            padding: 106px 0;
        }
    }

    .section-title {
        font-size: clamp(1.8rem, 3vw, 2.4rem);
        font-weight: 600;

        @include max-media-md {
            margin-bottom: 50px;
        }

        @include media-lg {
            margin-bottom: 64px;
        }

        @include media-xl {
            margin-bottom: 76px;
        }

        @include media-xxl {
            margin-bottom: 80px;
        }
    }
}
