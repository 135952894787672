@mixin media-xs {
    @media (max-width: 575px) {
        @content;
    }
}
@mixin media-sm {
    @media (min-width: 576px) and (max-width: 767px) {
        @content;
    }
}
@mixin media-md {
    @media (min-width: 768px) and (max-width: 991px) {
        @content;
    }
}
@mixin media-lg {
    @media (min-width: 992px) and (max-width: 1199px) {
        @content;
    }
}
@mixin media-xl {
    @media (min-width: 1200px) and (max-width: 1399px) {
        @content;
    }
}
@mixin media-xxl {
    @media (min-width: 1400px) {
        @content;
    }
}
@mixin max-media-sm {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin max-media-md {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin max-media-lg {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin min-media-lg {
    @media (min-width: 992px) {
        @content;
    }
}
